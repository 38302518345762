<template>
  <v-row justify="center">
    <v-col xl="10">
      <v-dialog max-width="700px" v-model="createDialog">
        <v-card>
          <v-form @submit.prevent="createDonationForm" ref="createForm" v-model="valid">
            <v-card-title>
              <span class="headline">{{ $t("admin.donationForm.forms.new.title") }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    :label="$t('admin.donationForm.forms.new.formName')"
                    :rules="[$v.required()]"
                    autocomplete="off"
                    name="newForm.name"
                    required
                    v-model="newFormName"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="createDialog = false" color="grey" text>
                {{ $t("admin.donationForm.forms.new.cancel") }}
              </v-btn>
              <v-btn :disabled="!valid" color="primary" type="submit">
                {{ $t("admin.donationForm.forms.new.create") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-card class="page-card">
        <v-card-title>
          <h2>
            {{ $t("admin.donationForm.forms.title") }}
          </h2>
          <v-btn class="ml-10" color="primary" v-on:click="createDialog = true">
            <v-icon class="mr-2">mdi-plus</v-icon>
            {{ $t("admin.donationForm.forms.actions.add") }}
          </v-btn>
        </v-card-title>

        <v-container>
          <v-row>
            <v-col cols="12" md="6" lg="7">
              <v-list>
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  :to="`/donationForm/charity/${$route.params.charityId}/forms/${item.id}/dashboard`"
                  ripple
                  color="primary"
                  class="form-list-item py-2"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <h3>{{ item.name }}</h3>
                      <span
                        v-if="item.publishedToPortal"
                        class="mt-2 mr-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--x-small info"
                      >
                        {{ $t("admin.donationForm.forms.publishedToPortalTag") }}
                      </span>
                      <span
                        v-if="isClosed(item)"
                        class="mt-2 mr-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--x-small error"
                      >
                        {{ $t("admin.donationForm.forms.formClosed") }}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn color="primary">
                      {{ $t("admin.donationForm.forms.actions.manage") }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <div v-if="isDonationFormListEmpty" class="pa-4">
                <p class="font-weight-thin text-center">
                  {{ $t("admin.donationForm.forms.noDonationFormsText") }}
                </p>
              </div>
              <div class="text-center mt-5" v-if="hasMore">
                <v-btn text @click="showArchivedDonationForms()" color="primary">
                  {{ $t("admin.donationForm.forms.showArchivedDonationForms") }}
                </v-btn>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="6" lg="5">
              <v-card color="#D7FAF0">
                <v-card-title color="secondary">
                  <v-icon class="mr-5">mdi-information-outline</v-icon>
                  {{ $t("admin.donationForm.forms.organizing.title") }}
                </v-card-title>
                <v-card-text color="secondary">
                  <p class="text-justify">
                    {{ $t("admin.donationForm.forms.organizing.text") }}
                  </p>
                </v-card-text>
                <!--
                <v-card-actions class="justify-center">
                  <v-btn @click="openHelpChat()" target="_blank" raised class="mt-1 mb-5" color="primary">{{
                    $t("admin.donationForm.forms.organizing.contactUs")
                  }}</v-btn>
                </v-card-actions>
                -->
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      hasMore: true,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      items: [],
      loading: true,
      createDialog: false,
      valid: false,
      contactUsUrl: "",
      newFormName: "",
    };
  },
  computed: {
    isDonationFormListEmpty() {
      return !this.items?.length ? true : false;
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async createDonationForm() {
      const result = await this.$axios.post(`/donationForms/${this.$store.state.charity.id}/donationForm/create`, {
        name: this.newFormName,
      });
      this.$store.commit("SUCCESS", this.$t("admin.donationForm.forms.msg_created"));
      this.$router.push(`/donationForm/charity/${this.$store.state.charity.billingId}/forms/${result.id}/settings`);
    },
    async showArchivedDonationForms() {
      const { page, itemsPerPage } = this.pagination;
      this.loading = true;
      const result = await this.$axios.get(`/donationForms/${this.$store.state.charity.id}/forms/past`, {
        params: {
          page: page - 1,
          size: itemsPerPage,
        },
      });
      this.hasMore = result.hasMore;
      result.forms.forEach((row) => {
        this.items.push(row);
      });
      this.loading = false;
      this.pagination.page++;
    },
    async getDataFromApi() {
      this.loading = true;
      const result = await this.$axios.get(`/donationForms/${this.$store.state.charity.id}/forms`);
      this.items = result.rows;
      this.hasMore = result.hasMore;
      this.loading = false;
    },
    openHelpChat() {
      // window.$zoho.salesiq.floatwindow.visible("show");
    },
    isClosed(item) {
      return item.closingDate && moment(item.closingDate).isSameOrBefore(new Date());
    },
  },
};
</script>

<style>
.v-data-footer__select {
  display: none;
}
.form-list-item {
  border-bottom: 1px solid #ddd;
}
.form-list-item:last-child {
  border-bottom: none;
}
.page-card {
  min-height: 500px;
}
</style>
